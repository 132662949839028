import React from 'react'
import { ThemeProvider } from 'styled-components'
import { Theme } from '@pga/pga-component-library'

import './src/styles/main.css'
import './src/styles/extra.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

/**
 * Wrap root entry element
 */

export const wrapRootElement = ({ element }) => {
  return <ThemeProvider theme={Theme}>
    {element}
  </ThemeProvider>
}
